import { Box, Text, Flex, Badge, Button, Avatar } from "@chakra-ui/react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import Footer from "../components/Footer";
import { SavingsMeterWithContainer } from "../components/savings-meter";
//@ts-ignore
import PDF from "../static/leaflets/Reliance_Nippon_Life_Nishchit_Samrudhi_Leaflet.pdf";

const ConnectWithAgent = () => {
  return (
    <ContainerWithHeader>
      <ScreenContent>
        <Box textAlign="center" mx="auto" mt={6} mb={3}>
          <Avatar size="2xl" />
        </Box>

        <Box textAlign="center" color="white" fontSize="xl">
          <Text my={4} fontSize="x-large" color="yellow.500">
            Thank You for your Interest
          </Text>

          <Text mb={4}>
            By selecting this you are one step closer to achieve your dream.
          </Text>

          <Text>One of Our executive will connect with you shortly.</Text>
        </Box>
      </ScreenContent>
    </ContainerWithHeader>
  );
};

export default ConnectWithAgent;
