import React, { useEffect } from "react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import { Box, Text, Button, Grid, GridItem, Flex } from "@chakra-ui/react";
import goalsArray from "../config/data/goals";
import _ from "lodash";
import SavingsMeter, {
  SavingsMeterWithContainer,
} from "../components/savings-meter";
import {
  calculateAnnualExpenseSavingsScore,
  getAgeGroup,
} from "../helpers/calculateSavingsScore";
import STORAGE from "../config/storage";
import useStateWithLS from "../hooks/useStateWithLS";
import { useNavigate } from "react-router-dom";
import Image from "../components/atoms/image";

const FutureGoalsScreen = () => {
  const [selectedGoal, setSelectedGoal] = useStateWithLS(STORAGE.SELECTED_GOAL);
  const [savingsValue, setSavingsValue] = useStateWithLS(
    //@ts-ignore
    calculateAnnualExpenseSavingsScore()?.percent
  );
  const navigate = useNavigate();

  const ageGroup = localStorage.getItem(STORAGE.AGEGROUP) ?? "";

  useEffect(() => {
    if (setSelectedGoal) {
      setSavingsValue(10);
    }
  }, []);

  const onGoalSelected = () => {};

  const onNext = () => {
    navigate("/cost-of-goals");
  };

  return (
    <ContainerWithHeader>
      <ScreenContent justify="space-between">
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Financial Goal
          </Text>
          <Text>What is your top most financial Priority ?</Text>
        </Box>

        {/* <SavingsMeterWithContainer savingsValue={savingsValue}> */}
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          {_.map(goalsArray, (goal) =>
            goal.agegroups.includes(ageGroup) ? (
              <GoalBlock
                src={goal.img}
                title={goal?.title}
                isActive={selectedGoal === goal.slug}
                onClick={() => {
                  setSelectedGoal(goal.slug);
                  setSavingsValue(10);
                }}
              />
            ) : (
              <></>
            )
          )}
        </Grid>
        {/* </SavingsMeterWithContainer> */}

        <Button
          isDisabled={!selectedGoal}
          onClick={onNext}
          mt={4}
          width="100%"
          colorScheme="secondary"
        >
          Next
        </Button>
      </ScreenContent>
    </ContainerWithHeader>
  );
};

export default FutureGoalsScreen;

interface IGoalBlock {
  title: string;
  isActive?: boolean;
  src: string;
  onClick?: () => void;
}

const GoalBlock = ({ title, isActive, onClick, src }: IGoalBlock) => {
  return (
    <Box
      borderWidth="1px"
      borderColor={isActive ? "primary.500" : "gray.500"}
      width="100%"
      borderRadius="sm"
      bg={isActive ? "primary.500" : "transparent"}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <Box
        width="100%"
        height="125px"
        bgImage={`url(${src})`}
        bgSize="cover"
        bgPosition="center center"
      />
      <Text fontSize="sm" color="text.regular" textAlign="center" p={2}>
        {title}
      </Text>
    </Box>
  );
};
