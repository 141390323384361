import React, { useState, useEffect } from "react";

const useStateWithLS = (
  paramName?: string,
  initialState?: any,
  isObject?: boolean
) => {
  const getInitialState = () => {
    if (initialState) return initialState;

    if (paramName) {
      if (isObject) {
        return JSON.parse(localStorage.getItem(paramName) || "{}");
      }

      return localStorage.getItem(paramName);
    }

    return null;
  };

  const [state, setState] = useState(getInitialState());

  useEffect(() => {
    if (paramName) {
      if (isObject) {
        localStorage.setItem(paramName, JSON.stringify(state));
        return;
      }

      localStorage.setItem(paramName, state);
    }
  }, [state]);

  return [state, setState];
};

export default useStateWithLS;
