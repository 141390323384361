import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AgeScreen from "./screens/age-screen";
import AnnualExpenseScreen from "./screens/annual-expense";
import AnnualIncomeScreen from "./screens/annual-income";
import CostOfGoal from "./screens/cost-of-goal";
import FutureGoalsScreen from "./screens/future-goal";
import HomeScreen from "./screens/home-screen";
import NischitSamruddhiDetails from "./screens/nischit-samruddhi-details";
import NischitSamruddhi from "./screens/nischit-samruddhi";
import TotalSavingsScreen from "./screens/total-savings";
import ConnectWithAgent from "./screens/connect-with-agent";
import AlternativePlans from "./screens/alternative-plans";
import GuarantedMoneyBackPlan from "./screens/guaranted-money-back";
import SuperMoneyBack from "./screens/suoer-money-back";
import MileStonePlan from "./screens/milestone";

const Navigation = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/age" element={<AgeScreen />} />
        <Route path="/annual-income" element={<AnnualIncomeScreen />} />
        <Route path="/total-savings" element={<TotalSavingsScreen />} />
        <Route path="/annual-expense" element={<AnnualExpenseScreen />} />
        <Route path="/future-goals" element={<FutureGoalsScreen />} />
        <Route path="/cost-of-goals" element={<CostOfGoal />} />
        <Route path="/nischit-samruddhi" element={<NischitSamruddhi />} />
        <Route
          path="/nischit-samruddhi-details"
          element={<NischitSamruddhiDetails />}
        />
        <Route path="/connect-with-agent" element={<ConnectWithAgent />} />
        <Route path="/alternative-plans" element={<AlternativePlans />} />
        <Route
          path="/rnl-guaranted-money-back-plan"
          element={<GuarantedMoneyBackPlan />}
        />
        <Route path="/rnl-super-money-back-plan" element={<SuperMoneyBack />} />
        <Route path="/rnl-milestone-plan" element={<MileStonePlan />} />
      </Routes>
    </Router>
  );
};

export default Navigation;
