const ageArray = [
  {
    age: "18-24",
    quote: `"WoW! You are part of Social networks and Digital natives" `,
    img: "18.png",
    income: [
      {
        start: 0,
        end: 7,
        meterPercent: 50,
        savings: [
          {
            start: 0,
            end: 0,
            meterPercent: 30,
          },
          {
            start: 0,
            end: 5,
            meterPercent: 40,
          },
          {
            start: 6,
            end: 100,
            meterPercent: 40,
          },
        ],
      },
      {
        start: 7,
        end: 14,
        meterPercent: 70,
      },
      {
        start: 14,
        end: 100,
        meterPercent: 100,
      },
    ],
  },
  {
    age: "25-34",
    quote: `"Wow you are a billionaire when comes to time - Billion seconds completed" `,
    img: "25.png",
    income: [
      {
        start: 0,
        end: 10,
        meterPercent: 50,
      },
      {
        start: 10,
        end: 20,
        meterPercent: 70,
      },
      {
        start: 20,
        end: 100,
        meterPercent: 100,
      },
    ],
  },
  {
    age: "35-45",
    quote: ` "WOW! You have experienced the first cycle of internet on 15 Aug 1995" `,
    img: "35.png",
    income: [
      {
        start: 0,
        end: 18,
        meterPercent: 50,
      },
      {
        start: 18,
        end: 35,
        meterPercent: 70,
      },
      {
        start: 35,
        end: 100,
        meterPercent: 100,
      },
    ],
  },
  {
    age: "46-55",
    quote: ` "Amazing! You have lived through an era of Technology development"`,
    img: "45.png",
    income: [
      {
        start: 0,
        end: 30,
        meterPercent: 50,
      },
      {
        start: 30,
        end: 50,
        meterPercent: 70,
      },
      {
        start: 50,
        end: 100,
        meterPercent: 100,
      },
    ],
  },
];

export default ageArray;
