import React, { useState } from "react";
import { Box, Text, Flex, Button, ButtonGroup, Avatar } from "@chakra-ui/react";
import Input from "../atoms/input";
import useStateWithLS from "../../hooks/useStateWithLS";
import STORAGE from "../../config/storage";
import { BiPhone } from "react-icons/bi";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import GenderEnum from "../../config/enums/gender";
import { createImagePath } from "../atoms/image";
import { useNavigate } from "react-router-dom";

const BasicDetailsForm = () => {
  const [name, setName] = useStateWithLS(STORAGE.USERNAME, "");
  const [gender, setGender] = useStateWithLS(STORAGE.GENDER, GenderEnum.MALE);
  const [nameError, setNameError] = useState("");
  const navigate = useNavigate();

  const onFormSubmit = () => {
    setNameError("");

    if (name && name?.trim().length <= 0) {
      setNameError("Name is required");
      return;
    }

    navigate("/age");
  };

  return (
    <Box mt={4}>
      <Box mb={6} textAlign="center">
        <Avatar
          size="xl"
          src={
            gender == GenderEnum.MALE
              ? createImagePath("/images/male.png")
              : createImagePath("/images/female.png")
          }
          name="gender"
        />
      </Box>

      <Text color="text.regular" mb={1}>
        Enter your Name
      </Text>
      <Input
        placeholder="Enter your name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        mb={6}
        errorMessage={nameError}
      />

      <Box mb={6}>
        <Text color="text.regular" mb={1}>
          Select your gender
        </Text>
        <Flex>
          <Button
            width="100%"
            leftIcon={<BsGenderMale />}
            colorScheme="primary"
            borderTopRightRadius="0"
            borderBottomRightRadius="0"
            variant={gender == GenderEnum.MALE ? "solid" : "outline"}
            onClick={() => setGender(GenderEnum.MALE)}
          >
            Male
          </Button>
          <Button
            width="100%"
            rightIcon={<BsGenderFemale />}
            colorScheme="primary"
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            variant={gender == GenderEnum.FEMALE ? "solid" : "outline"}
            onClick={() => setGender(GenderEnum.FEMALE)}
          >
            Female
          </Button>
        </Flex>
      </Box>

      <Box>
        <Button
          isDisabled={name?.trim().length === 0 ? true : false}
          width="100%"
          colorScheme="secondary"
          onClick={onFormSubmit}
        >
          Let's Get Started
        </Button>
      </Box>
    </Box>
  );
};

export default BasicDetailsForm;
