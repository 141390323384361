import React from "react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import { Flex, Text, Box, Avatar, Button } from "@chakra-ui/react";
import STORAGE from "../config/storage";
import ageArray from "../config/data/age";
import _ from "lodash";
import useStateWithLS from "../hooks/useStateWithLS";
import { useNavigate } from "react-router-dom";
import Image from "../components/atoms/image";

const AgeScreen = () => {
  const name = localStorage.getItem(STORAGE.USERNAME);
  const [ageGroup, setAgeGroup] = useStateWithLS(STORAGE.AGEGROUP);
  const navigate = useNavigate();

  const onNext = () => {
    navigate("/annual-income");
  };

  return (
    <ContainerWithHeader>
      <ScreenContent justify="space-between">
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Current Age
          </Text>
          <Text> {`${name}`}, please select your current age group.</Text>
        </Box>

        {_.map(ageArray, (age) => (
          <AgeBlock
            {...age}
            isSelected={age.age == ageGroup ? true : false}
            onClick={() => {
              setAgeGroup(age.age);
            }}
            img={age.img}
          />
        ))}

        <Button
          onClick={onNext}
          isDisabled={!ageGroup}
          width="100%"
          colorScheme="secondary"
        >
          Next
        </Button>
      </ScreenContent>
    </ContainerWithHeader>
  );
};

export default AgeScreen;

interface IAgeBlock {
  quote: string;
  age: string;
  img: string;
  isSelected?: boolean;
  onClick?: () => void;
}

const AgeBlock = (props: IAgeBlock) => {
  const gender = localStorage.getItem(STORAGE.GENDER);

  return (
    <Flex
      direction="row"
      p={2}
      borderWidth="1px"
      bg={props?.isSelected ? "primary.500" : "transparent"}
      borderColor={props?.isSelected ? "primary.500" : "gray.600"}
      borderRadius="sm"
      align="center"
      mb={4}
      onClick={() => {
        if (props?.onClick) props.onClick();
      }}
      boxShadow="lg"
    >
      <Box width="48px" minWidth="48px" mr={4}>
        <Image
          width="48px"
          height="48px"
          src={`/images/age/${gender}/${props?.img}`}
        />
      </Box>

      <Box>
        <Text color="text.regular"> {props?.age} years</Text>
        <Text fontSize="xs" color={props?.isSelected ? "white" : "gray.400"}>
          {props?.quote}
        </Text>
      </Box>
    </Flex>
  );
};
