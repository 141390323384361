import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

const Footer: FC = ({ children }) => {
  return (
    <Box
      width="100vw"
      maxWidth="600px"
      mx="auto"
      position="fixed"
      bottom="0"
      bg="black"
    >
      {children}
    </Box>
  );
};

export default Footer;
