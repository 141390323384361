import React, { FC, useState, useEffect } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import Slider from "./atoms/slider";
import { BsFillQuestionOctagonFill } from "react-icons/bs";
import { Tooltip } from "@chakra-ui/react";
import SavingsBlockMeter from "./savings-block-meter";

export interface ISavingsMeter {
  savingsValue?: number;
}

const SavingsMeter = (props: ISavingsMeter) => {
  const [savingsMeterValue, setSavingsMeterValue] = useState(0);

  return (
    <>
      <Box
        position="absolute"
        top={0}
        className="savingsMeterSliderWrapper"
        mt="150px"
        left="-125px"
      >
        <Flex direction="row">
          <Box className="questionMark">
            <Tooltip
              colorScheme="whiteAlpha"
              label="Savings Meter, Helps you indentify strength of your savings"
            >
              <Box>
                <Box as={BsFillQuestionOctagonFill} color="gray.400" />
              </Box>
            </Tooltip>
          </Box>
          <Box minWidth="300px" flex={1}>
            <Slider
              startPos={0}
              endPos={100}
              startVal={savingsMeterValue}
              onChange={(e) => {
                setSavingsMeterValue(e);
              }}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );

  return (
    <>
      {/* <Box
      display="inline"
      position="absolute"
      top={0}
      left={0}
      width="120px"
      height="100%"
    >
      <Flex direction="column">
       <Box
          width="325px"
         
          position="absolute"
          mt="150px"
          left="-125px"
        >
          <Slider
            startPos={0}
            endPos={100}
            startVal={savingsMeterValue}
            onChange={(e) => {
              setSavingsMeterValue(e);
            }}
          />
        </Box>
      </Flex>
          </Box> */}
    </>
  );
};

export default SavingsMeter;

const SavingsMeter2: FC<ISavingsMeter> = ({ savingsValue }) => {
  return (
    <Box width="85px" bg="red.200">
      <Text color="white">{savingsValue ? savingsValue : 0}</Text>
    </Box>
  );
};

export const SavingsMeterWithContainer: FC<ISavingsMeter> = (props) => {
  return (
    <Flex my={6} direction="row" position="relative" minHeight="325px">
      <SavingsBlockMeter {...props} />
      {/* <Box width="85px"></Box> */}
      <Box flex={1}>{props?.children}</Box>
    </Flex>
  );
};
