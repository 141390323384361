import React, { useState, useEffect } from "react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import { Flex, Text, Box, Avatar, Button, Input } from "@chakra-ui/react";
import STORAGE from "../config/storage";
import _ from "lodash";
import useStateWithLS from "../hooks/useStateWithLS";
import { useNavigate } from "react-router-dom";
import priceInWords from "../helpers/priceInWords";
import { SavingsMeterWithContainer } from "../components/savings-meter";
import Slider from "../components/atoms/slider";
import Image from "../components/atoms/image";
import { calculateAnnualExpenseSavingsScore } from "../helpers/calculateSavingsScore";

const AnnualExpenseScreen = () => {
  const name = localStorage.getItem(STORAGE.USERNAME);
  const navigate = useNavigate();

  const [startVal, setStartVal] = useState(25);
  const [annualExpense, setAnnualExpense] = useStateWithLS(
    STORAGE.ANNUAL_EXPENSE
  );
  const [savingsMeter, setSavingsMeter] = useState(0);

  useEffect(() => {
    setSavingsMeter(calculateAnnualExpenseSavingsScore().percent);
  }, [annualExpense]);

  const onNext = () => {
    navigate("/future-goals");
  };

  const onChangeAnnualExpense = (e: any) => {
    setAnnualExpense(parseInt(e?.target?.value));
    setStartVal(parseInt(e?.target?.value));
  };

  return (
    <ContainerWithHeader>
      <ScreenContent justify="space-between">
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Annual Expense
          </Text>
          <Text>Your approx yearly expense.</Text>
        </Box>

        <SavingsMeterWithContainer savingsValue={savingsMeter}>
          <Box textAlign="center">
            <Image
              mx="auto"
              width="200px"
              height="200px"
              src={`/images/expense.png`}
            />

            <Box my={16}>
              <Slider
                startPos={0}
                endPos={100}
                startVal={startVal}
                onChange={(e) => {
                  setAnnualExpense(e);
                }}
              />
            </Box>

            <Box>
              <Flex direction="row" align="baseline" justify="center">
                <Input
                  fontFamily="heading"
                  type="number"
                  textAlign="center"
                  color="white"
                  value={annualExpense}
                  onChange={onChangeAnnualExpense}
                  variant="flushed"
                  width="80px"
                  letterSpacing="1.2px"
                />
                <Text fontSize="sm" color="gray.200" mb={0} mt={1}>
                  lacs
                </Text>
              </Flex>

              <Text fontSize="xs" mb={0} mt={1} color="white" minHeight="16px">
                {priceInWords(annualExpense * 100000)}
              </Text>
            </Box>
          </Box>
        </SavingsMeterWithContainer>

        <Button onClick={onNext} width="100%" colorScheme="secondary">
          Next
        </Button>
      </ScreenContent>
    </ContainerWithHeader>
  );
};

export default AnnualExpenseScreen;
