import { Box, Text, Flex, Button } from "@chakra-ui/react";
import _ from "lodash";
import { FiCircle, FiCheckCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import SavingsMeter, {
  SavingsMeterWithContainer,
} from "../components/savings-meter";
import goalsArray from "../config/data/goals";
import STORAGE from "../config/storage";
import useStateWithLS from "../hooks/useStateWithLS";

const CostOfGoal = () => {
  const selectedGoal = localStorage.getItem(STORAGE.SELECTED_GOAL);
  const [option, setOption] = useStateWithLS(STORAGE.SELECTED_GOAL_OPTION);
  const navigate = useNavigate();

  const goalGroup = _.find(goalsArray, (goal) => {
    if (goal.slug === selectedGoal) {
      return true;
    }

    return false;
  });

  // REDIRECT BACK
  if (!goalGroup) {
  }

  const onNext = () => {
    navigate("/nischit-samruddhi");
  };

  return (
    <ContainerWithHeader>
      <ScreenContent>
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Approximate cost
          </Text>
          <Text>{goalGroup?.question}</Text>
        </Box>

        <SavingsMeterWithContainer savingsValue={10}>
          {_.map(goalGroup?.options, (opt: string) => (
            <GoalOption
              key={`goal-id-${opt}`}
              option={opt}
              isSelected={option === opt ? true : false}
              onClick={() => {
                setOption(opt);
              }}
            />
          ))}
          <Box>
            <Button
              isDisabled={!option}
              onClick={onNext}
              width="100%"
              colorScheme="secondary"
            >
              Next
            </Button>
          </Box>
        </SavingsMeterWithContainer>
      </ScreenContent>
    </ContainerWithHeader>
  );
};

export default CostOfGoal;

const GoalOption = (props: {
  isSelected?: boolean;
  onClick?: () => void;
  option: string;
}) => {
  return (
    <Flex
      direction="row"
      p={4}
      borderWidth="1px"
      bg={props?.isSelected ? "primary.500" : "transparent"}
      borderColor={props?.isSelected ? "primary.500" : "gray.600"}
      borderRadius="sm"
      align="center"
      mb={4}
      onClick={() => {
        if (props?.onClick) props.onClick();
      }}
      boxShadow="lg"
    >
      <Box mr={4} color={props?.isSelected ? "white" : "gray.600"}>
        <Box as={props?.isSelected ? FiCheckCircle : FiCircle} />
      </Box>

      <Box>
        <Text color="text.regular"> &#8377; {props?.option} </Text>
      </Box>
    </Flex>
  );
};
