import { ImageProps } from "@chakra-ui/react";
import Image from "./image";

interface ILogo extends ImageProps {}

const Logo = (props: ImageProps) => {
  return <Image src="/logo.png" {...props} />;
};

export default Logo;
