import {
  Box,
  Text,
  Flex,
  Badge,
  Button,
  Image as CImage,
} from "@chakra-ui/react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import { SavingsMeterWithContainer } from "../components/savings-meter";
import NischitSamruddhiLogo from "../static/images/NISCHIT_LOGO.png";
import { FaPiggyBank } from "react-icons/fa";
import { BsCoin } from "react-icons/bs";
import { MdOutlineFamilyRestroom } from "react-icons/md";
import { BsShieldPlus, BsArrowRepeat } from "react-icons/bs";
import { HiOutlineReceiptTax } from "react-icons/hi";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { GiStairsGoal } from "react-icons/gi";
//@ts-ignore
import PDF from "../static/leaflets/Leaflets/Reliance_Nippon_Life_Guaranteed_Money_Back_Plan_06_10_2020.pdf";

const GuaranteedMoneyBack = () => {
  const navigate = useNavigate();

  const onNext = () => {
    navigate("/nischit-samruddhi-details");
  };

  return (
    <ContainerWithHeader>
      <ScreenContent>
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Guaranteed Money Back Plan
          </Text>
          <Text>Guaranteed Savings Benefits</Text>

          <Badge bg="primary.500" color="white" p={1}>
            TAX BENEFIT
          </Badge>
        </Box>

        <SavingsMeterWithContainer savingsValue={80}>
          <Text color="white" textAlign="center">
            As you show you shall reap,
          </Text>

          <Flex
            my={4}
            fontSize="sm"
            color="white"
            align="center"
            fontWeight="bold"
            justify="center"
          >
            <Text textAlign="center" color="white" mr={1}>
              Life Insurance
            </Text>{" "}
            ={" "}
            <Text mx={1} textAlign="center" color="primary.500">
              Protection
            </Text>{" "}
            +{" "}
            <Text ml={1} textAlign="center" color="secondary.500">
              Money Back
            </Text>
          </Flex>

          <Text color="white" textAlign="center">
            Returns are going down,secure your life and savings with RNL
            Guaranteed Money Back Plan
          </Text>

          <Box>
            <Text color="yellow.500" textAlign="center" mt={4} mb={2}>
              Key Benefits
            </Text>

            <Flex wrap="wrap" justify="space-evenly">
              <KeyBenefit
                benefit="Guaranteed Benefits"
                benefitIcon={FaPiggyBank}
              />
              <KeyBenefit
                benefit="Avoid Reinvestment "
                benefitIcon={BsArrowRepeat}
              />
              <KeyBenefit
                benefit="Fullfill Your Dreams"
                benefitIcon={GiStairsGoal}
              />

              <KeyBenefit
                benefit="Tax Benefits"
                benefitIcon={HiOutlineReceiptTax}
              />
            </Flex>
          </Box>
        </SavingsMeterWithContainer>

        <br />
        <br />
      </ScreenContent>
      <FixedFooter />
    </ContainerWithHeader>
  );
};

export default GuaranteedMoneyBack;

interface IkeyBenefit {
  benefit: string;
  benefitIcon?: any;
}

const KeyBenefit = ({ benefit, benefitIcon }: IkeyBenefit) => {
  return (
    <Box width="33.3%" p={1} textAlign="center">
      <Flex
        mx="auto"
        width="45px"
        height="45px"
        bg="yellow.500"
        borderRadius="50%"
        align="center"
        justify="center"
        color="white"
      >
        <Box fontSize="x-large" as={benefitIcon} />
      </Flex>
      <Text fontSize="xs" color="white">
        {benefit}
      </Text>
    </Box>
  );
};

const FixedFooter = () => {
  const navigate = useNavigate();

  return (
    <Footer>
      <Flex p={4} pb={6}>
        <a href={PDF} rel="noopener noreferrer" target="_blank">
          <Button
            colorScheme="yellow"
            fontWeight="300"
            variant="outline"
            flex={1}
            fontSize="xs"
            mr={1}
          >
            Click here for more details
          </Button>
        </a>

        <Button
          onClick={() => {
            navigate("/connect-with-agent");
          }}
          ml={1}
          colorScheme="yellow"
          fontWeight="300"
          flex={1}
          fontSize="xs"
        >
          Connect with Agent
        </Button>
      </Flex>
    </Footer>
  );
};
