import {
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Icon,
  Text,
  Link,
  Divider,
} from "@chakra-ui/react";
import { BsWhatsapp } from "react-icons/bs";
import { BiPhone } from "react-icons/bi";
import Logo from "../atoms/logo";

const Header = () => {
  return (
    <Flex bg="secondary.500" p={1} align="center" justify="space-between">
      <Logo height="50px" />

      <Box mx={2}>
        <Popover>
          <PopoverTrigger>
            <Box>
              <Box fontSize="xl" color="green.500" as={BsWhatsapp} />
            </Box>
          </PopoverTrigger>
          <PopoverContent maxWidth="175px" mr={4}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Reach Us At</PopoverHeader>
            <PopoverBody px={0}>
              <Box>
                <Link>
                  <Flex align="center" px={2}>
                    <Box width="24px" color="primary.500">
                      <Box as={BiPhone} />
                    </Box>
                    <Text fontWeight="primary.500">+ 91 12345 67890</Text>
                  </Flex>
                </Link>
              </Box>
              <Divider my={2} />
              <Box>
                <Link>
                  <Flex align="center" px={2}>
                    <Box width="24px" color="green.500">
                      <Box as={BsWhatsapp} />
                    </Box>
                    <Text fontWeight="primary.500">+ 91 12345 67890</Text>
                  </Flex>
                </Link>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </Flex>
  );
};

export default Header;
