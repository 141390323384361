import React, { FC } from "react";
import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/react";

interface IScreenContent extends FlexProps {}

const ScreenContent: FC<IScreenContent> = (props) => {
  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      minHeight="calc(100vh - 58px)"
      flex={1}
      p={4}
      {...props}
    >
      {props.children}
    </Flex>
  );
};

export default ScreenContent;
