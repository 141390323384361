import {
  Box,
  Text,
  Flex,
  Badge,
  Button,
  Image as CImage,
  Divider,
} from "@chakra-ui/react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import { SavingsMeterWithContainer } from "../components/savings-meter";
import NischitSamruddhiLogo from "../static/images/NISCHIT_LOGO.png";
import { FaPiggyBank } from "react-icons/fa";
import { BsCoin } from "react-icons/bs";
import { MdOutlineFamilyRestroom } from "react-icons/md";
import { BsShieldPlus } from "react-icons/bs";
import { HiOutlineReceiptTax } from "react-icons/hi";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const AlternativePlans = () => {
  const navigate = useNavigate();

  const onNext = () => {
    navigate("/nischit-samruddhi-details");
  };

  return (
    <ContainerWithHeader>
      <ScreenContent>
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Alternative Plans
          </Text>
          <Text>Click on any of the below plans to get more details</Text>
        </Box>

        <SavingsMeterWithContainer>
          <Text color="white">A. Plans with lesser yearly premiums</Text>
          <Box my={4}>
            <PlanOption
              option={`Rs. 75,000 /-`}
              isSelected={true}
              onClick={() => {
                navigate("/rnl-super-money-back-plan");
              }}
            />
            <PlanOption
              option={`Rs. 50,000 /-`}
              isSelected={true}
              onClick={() => {
                navigate("/rnl-milestone-plan");
              }}
            />
          </Box>
          <Divider my={4} />

          <Text color="white">B. Plans with different goals/solutions</Text>
          <Box my={4}>
            <PlanOption
              option={`Reliance Nippon Life Milestone Plan.`}
              isSelected={true}
              onClick={() => {
                navigate("/rnl-milestone-plan");
              }}
            />{" "}
            <PlanOption
              onClick={() => {
                navigate("/rnl-guaranted-money-back-plan");
              }}
              option={`Reliance Nippon Life Guaranteed Money Back Plan.`}
              isSelected={true}
            />
          </Box>
        </SavingsMeterWithContainer>

        <br />
        <br />
      </ScreenContent>
    </ContainerWithHeader>
  );
};

export default AlternativePlans;

const PlanOption = (props: {
  isSelected?: boolean;
  onClick?: () => void;
  option: string;
}) => {
  return (
    <Flex
      direction="row"
      p={4}
      borderWidth="1px"
      bg={props?.isSelected ? "primary.500" : "transparent"}
      borderColor={props?.isSelected ? "primary.500" : "gray.600"}
      borderRadius="sm"
      align="center"
      mb={4}
      onClick={() => {
        if (props?.onClick) props.onClick();
      }}
      boxShadow="lg"
    >
      <Box>
        <Text color="text.regular"> {props?.option} </Text>
      </Box>
    </Flex>
  );
};
