import {
  Box,
  Text,
  Flex,
  Badge,
  Button,
  Image as CImage,
} from "@chakra-ui/react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import { SavingsMeterWithContainer } from "../components/savings-meter";
import NischitSamruddhiLogo from "../static/images/NISCHIT_LOGO.png";
import { FaPiggyBank } from "react-icons/fa";
import { BsCoin } from "react-icons/bs";
import { MdOutlineFamilyRestroom } from "react-icons/md";
import { BsShieldPlus, BsArrowRepeat, BsCashStack } from "react-icons/bs";
import { HiOutlineReceiptTax } from "react-icons/hi";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { GiStairsGoal, GiFireworkRocket, GiPayMoney } from "react-icons/gi";

//@ts-ignore
import PDF from "../static/leaflets/Leaflets/Reliance_Nippon_Life_Guaranteed_Money_Back_Plan_06_10_2020.pdf";

const SuperMoneyBack = () => {
  const navigate = useNavigate();

  const onNext = () => {
    navigate("/nischit-samruddhi-details");
  };

  return (
    <ContainerWithHeader>
      <ScreenContent>
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Super Money Back Plan
          </Text>
          <Text>
            Money back insurance plan that gives guaranteed* payouts at periodic
            intervals
          </Text>

          <Badge bg="primary.500" color="white" p={1}>
            TAX BENEFIT
          </Badge>
        </Box>

        <SavingsMeterWithContainer savingsValue={80}>
          <Text color="white">
            Get guaranteed, Monthly returns in these uncertain times to fulfill
            your family's desires.
          </Text>

          <Text color="white" my={4}>
            A non-linked, non-participating, non-variable, money back insurance
            plan that gives guaranteed* payouts at periodic intervals
          </Text>

          <Box>
            <Text color="yellow.500" textAlign="center" mt={4} mb={2}>
              Key Benefits
            </Text>

            <Flex wrap="wrap" justify="space-evenly">
              <KeyBenefit
                benefit="Guaranteed* Liquidity"
                benefitIcon={BsCashStack}
              />
              <KeyBenefit
                benefit="Boost Your Savings "
                benefitIcon={GiFireworkRocket}
              />
              <KeyBenefit
                benefit="Protection for your family"
                benefitIcon={BsShieldPlus}
              />

              <KeyBenefit benefit="Pay as you like" benefitIcon={GiPayMoney} />

              <KeyBenefit
                benefit="Tax Benefits"
                benefitIcon={HiOutlineReceiptTax}
              />
            </Flex>
          </Box>
        </SavingsMeterWithContainer>

        <br />
        <br />
      </ScreenContent>
      <FixedFooter />
    </ContainerWithHeader>
  );
};

export default SuperMoneyBack;

interface IkeyBenefit {
  benefit: string;
  benefitIcon?: any;
}

const KeyBenefit = ({ benefit, benefitIcon }: IkeyBenefit) => {
  return (
    <Box width="33.3%" p={1} textAlign="center">
      <Flex
        mx="auto"
        width="45px"
        height="45px"
        bg="yellow.500"
        borderRadius="50%"
        align="center"
        justify="center"
        color="white"
      >
        <Box fontSize="x-large" as={benefitIcon} />
      </Flex>
      <Text fontSize="xs" color="white">
        {benefit}
      </Text>
    </Box>
  );
};

const FixedFooter = () => {
  const navigate = useNavigate();

  return (
    <Footer>
      <Flex p={4} pb={6}>
        <a href={PDF} rel="noopener noreferrer" target="_blank">
          <Button
            colorScheme="yellow"
            fontWeight="300"
            variant="outline"
            flex={1}
            fontSize="xs"
            mr={1}
          >
            Click here for more details
          </Button>
        </a>

        <Button
          onClick={() => {
            navigate("/connect-with-agent");
          }}
          ml={1}
          colorScheme="yellow"
          fontWeight="300"
          flex={1}
          fontSize="xs"
        >
          Connect with Agent
        </Button>
      </Flex>
    </Footer>
  );
};
