import { ContainerWithHeader } from "../components/atoms/container";
import Logo from "../components/atoms/logo";
import { Box, Text } from "@chakra-ui/react";
import BasicDetailsForm from "../components/home/basic-details-form";
import ScreenContent from "../components/atoms/screen-content";

const HomeScreen = () => {
  return (
    <ContainerWithHeader>
      <ScreenContent>
        <Text
          fontFamily="heading"
          color="text.regular"
          fontSize="3xl"
          letterSpacing="2px"
        >
          Hello &#128075;,
        </Text>

        <Text color="text.regular">
          Are your financial worries keeping you awake at night ? Let us help
          you plan better to achieve your{" "}
          <span
            style={{
              fontWeight: "bold",
              color: "var(--chakra-colors-secondary-500)",
            }}
          >
            Financial Goals
          </span>
          &nbsp; &#128176;
        </Text>

        <Box mt={6}>
          <Text textAlign="center" color="gray.400">
            Please fill in the following details to get started
          </Text>
          <BasicDetailsForm />
        </Box>
      </ScreenContent>
    </ContainerWithHeader>
  );
};

export default HomeScreen;
