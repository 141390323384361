const STORAGE_APP_INITIAL = "__saving";
const STORAGE_APP_VERSION = "0.01";

const createParamValue = (param: string) => {
  return `${STORAGE_APP_INITIAL}-${STORAGE_APP_VERSION}-${param}`;
};

const STORAGE = {
  USERNAME: createParamValue("NAME"),
  GENDER: createParamValue("GENDER"),
  AGEGROUP: createParamValue("AGEGROUP"),
  ANNUALINCOME: createParamValue("ANNUALINCOME"),
  TOTAL_SAVINGS: createParamValue("TOTALSAVINGS"),
  ANNUAL_EXPENSE: createParamValue("ANNUALEXPENSE"),
  SELECTED_GOAL: createParamValue("SELECTEDGOAL"),
  SELECTED_GOAL_OPTION: createParamValue("SELECTEDGOALOPTION"),
};

export default STORAGE;
