import React, { FC } from "react";
import {
  Box,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { FaHandPointLeft } from "react-icons/fa";
import { BsFillPatchQuestionFill } from "react-icons/bs";

import { ISavingsMeter } from "./savings-meter";

const SavingsBlockMeter: FC<ISavingsMeter> = ({ savingsValue }) => {
  return (
    <Box p={4} pl={0} position="relative">
      <Text textAlign="center" color="white">
        {savingsValue ? savingsValue : 0} %{" "}
      </Text>
      <Flex
        direction="column"
        width="32px"
        height="calc(100% - 12px)"
        maxHeight="400px"
        bg="red.200"
        borderRadius="25px"
        borderWidth="4px"
        borderColor="gray.100"
        position="relative"
      >
        <Box
          flex={1}
          bg="green.500"
          borderTopLeftRadius="25px"
          borderTopRightRadius="25px"
        ></Box>
        <Box flex={1} bg="yellow.500"></Box>
        <Box flex={1} bg="orange.500"></Box>
        <Box
          flex={1}
          bg="red.500"
          borderBottomLeftRadius="25px"
          borderBottomRightRadius="25px"
        ></Box>

        <FloatPercent percent={savingsValue ? savingsValue : 0} />
      </Flex>

      <SavingsMeterPopup />
    </Box>
  );
};

export default SavingsBlockMeter;

interface IFloatPercent {
  percent: number;
}

const FloatPercent = ({ percent }: IFloatPercent) => {
  return (
    <Flex
      height="20px"
      position="absolute"
      left={8}
      top={`calc(${100 - percent}% - 10px)`}
      align="center"
      zIndex={2050}
      className="pointer"
    >
      <Box mt={"-2px"} as={FaHandPointLeft} color="white" />
      <Flex ml={"-3px"} bg="white" p={2} py={1} borderRadius="lg" width="30px">
        <Text fontSize="xx-small" mb={0}>
          {percent}
        </Text>
      </Flex>
    </Flex>
  );
};

const SavingsMeterPopup = () => {
  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Box my={2} mx="2" textAlign="center">
            <Box as={BsFillPatchQuestionFill} color="gray.400" />
          </Box>
        </PopoverTrigger>
        <PopoverContent mx={4} zIndex={4050} position="relative">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Savings Meter</PopoverHeader>
          <PopoverBody>
            <Text fontSize="xs">Shows the strength of your savings</Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
