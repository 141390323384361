import React, { useState, useEffect } from "react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import { Flex, Text, Box, Avatar, Button, Input } from "@chakra-ui/react";
import STORAGE from "../config/storage";
import ageArray from "../config/data/age";
import _ from "lodash";
import useStateWithLS from "../hooks/useStateWithLS";
import { useNavigate } from "react-router-dom";
import Slider from "../components/atoms/slider";
import { SavingsMeterWithContainer } from "../components/savings-meter";
import Image from "../components/atoms/image";
import priceInWords from "../helpers/priceInWords";
import { getTotalSavingsImage } from "../helpers/getImages";
import { calculateTotalSavingsSavingScore } from "../helpers/calculateSavingsScore";

const TotalSavingsScreen = () => {
  const name = localStorage.getItem(STORAGE.USERNAME);
  const [savingsMeter, setSavingsMeter] = useState(0);
  const [startVal, setStartVal] = useState(0);
  const [totalSavings, setTotalSavings] = useStateWithLS(STORAGE.TOTAL_SAVINGS);

  useEffect(() => {
    setSavingsMeter(calculateTotalSavingsSavingScore().percent);
  }, [totalSavings]);

  const navigate = useNavigate();

  const onNext = () => {
    navigate("/annual-expense");
  };

  const onChangeTotalSavings = (e: any) => {
    setTotalSavings(parseInt(e?.target?.value));
    setStartVal(parseInt(e?.target?.value));
  };

  return (
    <ContainerWithHeader>
      <ScreenContent justify="space-between">
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Total Savings
          </Text>
          <Text opacity="0">Your yearly income from all sources in lacs.</Text>
        </Box>

        <SavingsMeterWithContainer savingsValue={savingsMeter}>
          <Box textAlign="center">
            <Image
              mx="auto"
              width="200px"
              height="200px"
              src={`/images/money/${getTotalSavingsImage(totalSavings)}`}
            />
          </Box>

          <Box my={16}>
            <Slider
              startPos={0}
              endPos={100}
              startVal={startVal}
              onChange={(e) => {
                setTotalSavings(e);
              }}
            />
          </Box>

          <Box textAlign="center">
            <Flex direction="row" align="baseline" justify="center">
              <Input
                fontFamily="heading"
                type="number"
                textAlign="center"
                color="white"
                value={totalSavings}
                onChange={onChangeTotalSavings}
                variant="flushed"
                width="80px"
                letterSpacing="1.2px"
              />
              <Text fontSize="sm" color="gray.200" mb={0} mt={1}>
                lacs
              </Text>
            </Flex>

            <Text fontSize="xs" mb={0} mt={1} color="white" minHeight="16px">
              {priceInWords(totalSavings * 100000)}
            </Text>
          </Box>
        </SavingsMeterWithContainer>

        <Button onClick={onNext} width="100%" colorScheme="secondary">
          Next
        </Button>
      </ScreenContent>
    </ContainerWithHeader>
  );
};

export default TotalSavingsScreen;
