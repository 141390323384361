import Nouislider from "nouislider-react";
import { Box } from "@chakra-ui/react";

interface ISlider {
  startPos: number;
  endPos: number;
  startVal?: number;
  onChange?: (e?: any) => void;
  isVertical?: boolean;
}

const Slider = ({
  startPos,
  endPos,
  startVal,
  onChange,
  isVertical,
}: ISlider) => {
  return (
    <Box mx={2} px={2}>
      <Nouislider
        range={{ min: startPos, max: endPos }}
        start={startVal ? startVal : 0}
        tooltips={true}
        animate={true}
        connect={false}
        pips={{ mode: "count", values: 9 }}
        orientation={isVertical ? "vertical" : "horizontal"}
        onUpdate={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        format={{
          from: function (value) {
            //@ts-ignore
            return parseInt(value);
          },
          to: function (value) {
            //@ts-ignore
            return parseInt(value);
          },
        }}
      />
    </Box>
  );
};

export default Slider;
