import React, { FC } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import Header from "../base/header";

interface IContainer extends BoxProps {}

const Container: FC<IContainer> = (props) => {
  return (
    <Box
      bg="page.bg"
      mx="auto"
      width="100%"
      maxWidth="600px"
      minHeight="100vh"
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default Container;

export const ContainerWithHeader: FC<IContainer> = (props) => {
  return (
    <Container {...props} minHeight="calc(100vh)">
      <Header />
      {props.children}
    </Container>
  );
};
