import {
  Box,
  Text,
  Flex,
  Badge,
  Button,
  Image as CImage,
} from "@chakra-ui/react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import { SavingsMeterWithContainer } from "../components/savings-meter";
import NischitSamruddhiLogo from "../static/images/NISCHIT_LOGO.png";
import { FaPiggyBank } from "react-icons/fa";
import { BsCoin } from "react-icons/bs";
import { MdOutlineFamilyRestroom } from "react-icons/md";
import { BsShieldPlus } from "react-icons/bs";
import { HiOutlineReceiptTax } from "react-icons/hi";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const NischitSamruddhi = () => {
  const navigate = useNavigate();

  const onNext = () => {
    navigate("/nischit-samruddhi-details");
  };

  return (
    <ContainerWithHeader>
      <ScreenContent>
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            SAVE YOUR SAVINGS
          </Text>
          <Text>To save you from substantial dip from your savings</Text>
        </Box>

        <SavingsMeterWithContainer>
          <Text color="white" textAlign="center">
            Some things in life need zyaada guarantee.
          </Text>

          <Box textAlign="center" mt={6}>
            <CImage mx="auto" src={NischitSamruddhiLogo} height="96px" />
          </Box>

          <Text textAlign="center" color="white">
            A guaranteed, tax-free income means a happy, stress-free life.
          </Text>

          <Box>
            <Text color="yellow.500" textAlign="center" mt={4} mb={2}>
              Key Benefits
            </Text>

            <Flex wrap="wrap" justify="space-evenly">
              <KeyBenefit
                benefit="Guaranteed Benefits"
                benefitIcon={FaPiggyBank}
              />
              <KeyBenefit benefit="Flexible Premium" benefitIcon={BsCoin} />
              <KeyBenefit
                benefit="Family Protection"
                benefitIcon={MdOutlineFamilyRestroom}
              />
              <KeyBenefit
                benefit="Enhanced Protection*"
                benefitIcon={BsShieldPlus}
              />
              <KeyBenefit
                benefit="Tax Benefits"
                benefitIcon={HiOutlineReceiptTax}
              />
            </Flex>
          </Box>
        </SavingsMeterWithContainer>

        <br />
        <br />
      </ScreenContent>
      <Footer>
        <Box p={4}>
          <Button onClick={onNext} width="100%" colorScheme="secondary">
            Next
          </Button>
        </Box>
      </Footer>
    </ContainerWithHeader>
  );
};

export default NischitSamruddhi;

interface IkeyBenefit {
  benefit: string;
  benefitIcon?: any;
}

const KeyBenefit = ({ benefit, benefitIcon }: IkeyBenefit) => {
  return (
    <Box width="33.3%" p={1} textAlign="center">
      <Flex
        mx="auto"
        width="45px"
        height="45px"
        bg="yellow.500"
        borderRadius="50%"
        align="center"
        justify="center"
        color="white"
      >
        <Box fontSize="x-large" as={benefitIcon} />
      </Flex>
      <Text fontSize="xs" color="white">
        {benefit}
      </Text>
    </Box>
  );
};
