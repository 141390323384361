import React, { FC, createContext, useReducer } from "react";
import InitialState, { IInitialState } from "./initial-state";
import reducer from "./reducer";

const StateContext = createContext<{
  state: IInitialState;
  dispatch: (action: { type: string; payload: any }) => void;
} | null>(null);

export default StateContext;

export const StateContextWrapper: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, InitialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};
