const goalsArray = [
  {
    slug: "marriage",
    title: "Marriage",
    options: ["5-10 lacs", "10-15 lacs", "15-20 lacs", "More than 20 lacs"],
    agegroups: ["18-24", "25-34"],
    question: "What would be expected cost for your marriage ?",
    img: "/images/goals/marriage.webp",
  },
  {
    slug: "child-education",
    title: "Child Education",
    options: ["5-10 lacs", "10-15 lacs", "15-20 lacs", "More than 20 lacs"],
    forLessThan35Only: true,
    agegroups: ["18-24", "25-34", "35-45", "46-55"],
    question: "What would be expected cost for your child's education?",
    img: "/images/goals/child-education.webp",
  },
  {
    slug: "new-house",
    title: "New House",
    options: ["10-25 lacs", "25-40 lacs", "40-55 lacs", "More than 55 lacs"],
    agegroups: ["18-24", "25-34", "35-45", "46-55"],
    question: "What would be expected cost for your New Dream House ?",
    img: "/images/goals/house.jpg",
  },
  {
    slug: "travel-abroad",
    title: "Travel Abroad",
    options: ["5-10 lacs", "10-15 lacs", "15-20 lacs", "More than 20 lacs"],
    agegroups: ["18-24", "25-34", "35-45", "46-55"],
    question: "What would be expected cost for travelling abroad ?",
    img: "/images/goals/travel-abroad.webp",
  },
  {
    slug: "fight-uncertainity",
    title: "Fight uncertainity ( disease or COVID)",
    options: ["5-10 lacs", "10-15 lacs", "15-20 lacs", "More than 20 lacs"],
    agegroups: ["18-24", "25-34", "35-45", "46-55"],
    question: "What would be expected cost to fight an uncertainity ?",
    img: "/images/goals/fight-covid.webp",
  },
  {
    slug: "retirement-plan",
    title: "Retirement Plan - Supplementary Income",
    options: ["5-10 lacs", "10-15 lacs", "15-20 lacs", "More than 20 lacs"],
    agegroups: ["35-45", "46-55"],
    question: "What would be expected cost for your Retirement ?",
    img: "/images/goals/retirement.jpg",
  },
  {
    slug: "child-marriage",
    title: "Child Marriage",
    options: ["5-15 lacs", "15-25 lacs", "25-35 lacs", "More than 35 lacs"],
    agegroups: ["18-24", "25-34", "35-45", "46-55"],
    question: "What would be expected cost for your child's marriage ?",
    img: "/images/goals/child-marriage.jpg",
  },
];

export default goalsArray;
