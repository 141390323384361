import { Box, Text, Flex, Badge, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import Footer from "../components/Footer";
import { SavingsMeterWithContainer } from "../components/savings-meter";
//@ts-ignore
import PDF from "../static/leaflets/Reliance_Nippon_Life_Nishchit_Samrudhi_Leaflet.pdf";
import { Link } from "react-router-dom";

const NischitSamruddhiDetails = () => {
  return (
    <ContainerWithHeader>
      <ScreenContent>
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Nishchit Samrudhi
          </Text>
          {/* <Text>
            Start Investing today to secure your goal {"&"} future needs
          </Text> */}
          <Badge bg="primary.500" color="white" p={1}>
            TAX BENEFIT
          </Badge>
        </Box>

        <SavingsMeterWithContainer savingsValue={80}>
          <Text color="white" fontSize="sm" textAlign="center">
            To save you from substantial dip in your savings, invest in{" "}
            <span style={{ color: "var(--chakra-colors-yellow-500)" }}>
              Nishchit Samrudhi Guranteed Return product
            </span>
            , to get guranteed return of
          </Text>

          <Flex
            fontSize="xx-large"
            color="gray.200"
            align="baseline"
            my={2}
            justify="space-around"
          >
            <Text color="yellow.500">
              &#8377; 2,00,000 <small>p.a.</small>{" "}
            </Text>
          </Flex>

          <Box my={6} mt={10} textAlign="center">
            <Text color="white" fontSize="sm">
              On Maturity you will receive
            </Text>

            <Flex
              fontSize="x-large"
              color="gray.200"
              align="baseline"
              my={2}
              justify="space-around"
            >
              <Text color="green.500">&#8377;17,18,065 </Text>
            </Flex>
          </Box>

          <Box mb={6} textAlign="center">
            <Text color="white" fontSize="sm">
              Receive yearly income benefit for 20 Years with total of
            </Text>

            <Flex
              fontSize="x-large"
              color="gray.200"
              align="baseline"
              my={2}
              justify="space-around"
            >
              <Text color="green.500">&#8377;17,18,065 </Text>
            </Flex>
          </Box>

          <Box>
            <Text color="gray.400" fontSize="sm">
              Alternatives :{" "}
              <Link to="/alternative-plans">
                {" "}
                <span style={{ color: "var(--chakra-colors-primary-500)" }}>
                  Click here
                </span>{" "}
              </Link>{" "}
              for other plans that would be able to partly satisfy your future
              needs and goals
            </Text>
          </Box>
        </SavingsMeterWithContainer>

        <br />
        <br />
        <br />
      </ScreenContent>
      <FixedFooter />
    </ContainerWithHeader>
  );
};

export default NischitSamruddhiDetails;

const FixedFooter = () => {
  const navigate = useNavigate();

  return (
    <Footer>
      <Flex p={4} pb={6}>
        <a href={PDF} rel="noopener noreferrer" target="_blank">
          <Button
            colorScheme="yellow"
            fontWeight="300"
            variant="outline"
            flex={1}
            fontSize="xs"
            mr={1}
          >
            Click here for more details
          </Button>
        </a>

        <Button
          onClick={() => {
            navigate("/connect-with-agent");
          }}
          ml={1}
          colorScheme="yellow"
          fontWeight="300"
          flex={1}
          fontSize="xs"
        >
          Connect with Agent
        </Button>
      </Flex>
    </Footer>
  );
};
