import STORAGE from "../config/storage";
import _ from "lodash";
import ageArray from "../config/data/dataArray";

export const getAgeGroup = () => {
  const age = localStorage.getItem(STORAGE.AGEGROUP)
    ? localStorage.getItem(STORAGE.AGEGROUP)
    : "";

  return _.find(ageArray, (a) => {
    if (age === a.age) {
      return true;
    }

    return false;
  });
};

export const calculateAnnualIncomeSavingsScore = () => {
  let percent = 0;

  const age = localStorage.getItem(STORAGE.AGEGROUP)
    ? localStorage.getItem(STORAGE.AGEGROUP)
    : "";

  const income = localStorage.getItem(STORAGE.ANNUALINCOME)
    ? parseInt(localStorage.getItem(STORAGE.ANNUALINCOME) ?? "0")
    : "";

  const ageGroup = getAgeGroup();

  if (ageGroup) {
    const incomeGroup = _.find(ageGroup.income, (inGrp) => {
      if (income > 0) {
        if (income >= inGrp?.start && income <= inGrp?.end) {
          return true;
        }
      } else {
        if (income > inGrp?.start && income <= inGrp?.end) {
          return true;
        }
      }

      return false;
    });

    if (incomeGroup) {
      return { percent: incomeGroup?.meterPercent, incomeGroup: incomeGroup };
    }
  }

  console.log(percent);

  return { percent: percent, incomeGroup: null };
};

export const calculateTotalSavingsSavingScore = () => {
  let percent = 0;
  const incomeGrp: any = calculateAnnualIncomeSavingsScore().incomeGroup;

  const totalSavings = localStorage.getItem(STORAGE.TOTAL_SAVINGS)
    ? parseInt(localStorage.getItem(STORAGE.TOTAL_SAVINGS) ?? "")
    : 0;

  const savingsGrp = _.find(incomeGrp?.savings, (saving) => {
    if (totalSavings > 0) {
      if (totalSavings >= saving?.start && totalSavings <= saving?.end) {
        return true;
      }
    } else {
      if (totalSavings > saving?.start && totalSavings <= saving?.end) {
        return true;
      }
    }

    return false;
  });

  if (savingsGrp) {
    percent = savingsGrp?.meterPercent;
  }

  return { percent: percent, savingsGroup: savingsGrp };
};

export const calculateAnnualExpenseSavingsScore = () => {
  let percent = 0;

  const incomeGrp: any = calculateAnnualIncomeSavingsScore().incomeGroup;

  const annualExpense = localStorage.getItem(STORAGE.ANNUAL_EXPENSE)
    ? parseInt(localStorage.getItem(STORAGE.ANNUAL_EXPENSE) ?? "")
    : 0;

  const expenseGroup: any = _.find(incomeGrp?.expense, (expense) => {
    if (annualExpense > 0) {
      if (annualExpense >= expense?.start && annualExpense <= expense?.end) {
        return true;
      }
    } else {
      if (annualExpense > expense?.start && annualExpense <= expense?.end) {
        return true;
      }
    }
  });

  if (expenseGroup) {
    const multiplier = expenseGroup?.meterMultiplier;
    const savingsGroup = calculateTotalSavingsSavingScore()?.savingsGroup;

    percent = savingsGroup?.meterPercent * multiplier;
  }

  return { percent: percent, expenseGroup: expenseGroup ? expenseGroup : null };
};
