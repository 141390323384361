import { ImageProps, Image as CImage } from "@chakra-ui/react";

interface IImage extends ImageProps {}

const Image = (props: IImage) => {
  return (
    <CImage
      {...props}
      src={props?.src ? process.env.PUBLIC_URL + props.src : ""}
    />
  );
};

export default Image;

export const createImagePath = (src: string) => {
  return process.env.PUBLIC_URL + src;
};
