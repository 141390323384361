import { Input as CInput, InputProps, Text } from "@chakra-ui/react";

interface IInput extends InputProps {
  errorMessage?: string;
}

const Input = (props: IInput) => {
  return (
    <>
      <CInput
        variant="outline"
        type="text"
        color="text.regular"
        borderColor="text.regular"
        {...props}
      />
      {props?.errorMessage ? (
        <Text color="red.400" fontSize="sm" mt={1} mb={4}>
          A small error over here
        </Text>
      ) : (
        ""
      )}
    </>
  );
};

export default Input;
