import React, { useState, useEffect } from "react";
import { ContainerWithHeader } from "../components/atoms/container";
import ScreenContent from "../components/atoms/screen-content";
import { Flex, Text, Box, Avatar, Button, Input } from "@chakra-ui/react";
import STORAGE from "../config/storage";
import ageArray from "../config/data/age";
import _ from "lodash";
import useStateWithLS from "../hooks/useStateWithLS";
import { useNavigate } from "react-router-dom";
import Slider from "../components/atoms/slider";
import { SavingsMeterWithContainer } from "../components/savings-meter";
import Image from "../components/atoms/image";
import priceInWords from "../helpers/priceInWords";
import { calculateAnnualIncomeSavingsScore } from "../helpers/calculateSavingsScore";

const AnnualIncomeScreen = () => {
  const name = localStorage.getItem(STORAGE.USERNAME);
  const navigate = useNavigate();
  const [startVal, setStartVal] = useState(25);
  const [annualIncome, setAnnualIncome] = useStateWithLS(STORAGE.ANNUALINCOME);
  const [savingsMeter, setSavingsMeter] = useState(0);

  useEffect(() => {
    if (!annualIncome) {
      setAnnualIncome(0);
      return;
    }

    setStartVal(annualIncome);
  }, []);

  useEffect(() => {
    setSavingsMeter(calculateAnnualIncomeSavingsScore().percent);
  }, [annualIncome]);

  const onNext = () => {
    navigate("/total-savings");
  };

  const onChangeAnnualIncome = (e: any) => {
    setAnnualIncome(parseInt(e?.target?.value));
    setStartVal(parseInt(e?.target?.value));
  };

  const getIncomeImage = () => {
    if (annualIncome > 75) return "76.png";

    if (annualIncome > 50) return "51.png";

    if (annualIncome > 25) return "26.png";

    return "0.png";
  };

  return (
    <ContainerWithHeader>
      <ScreenContent justify="space-between">
        <Box color="text.regular" mb={4}>
          <Text textTransform="uppercase" fontWeight={200} fontSize="x-large">
            Annual Income
          </Text>
          <Text>Your yearly income from all sources in lacs</Text>
        </Box>

        <SavingsMeterWithContainer savingsValue={savingsMeter}>
          <Box textAlign="center">
            <Image
              mx="auto"
              width="200px"
              height="200px"
              src={`/images/income/${getIncomeImage()}`}
            />

            <Box my={16}>
              <Slider
                startPos={0}
                endPos={100}
                startVal={startVal}
                onChange={(e) => {
                  setAnnualIncome(e);
                }}
              />
            </Box>

            <Box>
              <Flex direction="row" align="baseline" justify="center">
                <Input
                  fontFamily="heading"
                  type="number"
                  textAlign="center"
                  color="white"
                  value={annualIncome}
                  onChange={onChangeAnnualIncome}
                  variant="flushed"
                  width="80px"
                  letterSpacing="1.2px"
                />
                <Text fontSize="sm" color="gray.200" mb={0} mt={1}>
                  lacs
                </Text>
              </Flex>

              <Text fontSize="xs" mb={0} mt={1} color="white" minHeight="16px">
                {priceInWords(annualIncome * 100000)}
              </Text>
            </Box>
          </Box>
        </SavingsMeterWithContainer>

        <Button
          isDisabled={!annualIncome}
          onClick={onNext}
          width="100%"
          colorScheme="secondary"
        >
          Next
        </Button>
      </ScreenContent>
    </ContainerWithHeader>
  );
};

export default AnnualIncomeScreen;
