import React from "react";

import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import Navigation from "./navigation";
import { StateContextWrapper } from "./context";
import "nouislider/distribute/nouislider.css";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <StateContextWrapper>
          <Navigation />
        </StateContextWrapper>
      </ChakraProvider>
    </div>
  );
}

export default App;
