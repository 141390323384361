import ACTIONS from "../actions";
import { IInitialState } from "../initial-state";

export default function reducer(
  state: IInitialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case ACTIONS.TEST:
      return { ...state };

    default:
      return state;
  }
}
